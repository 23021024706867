@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --fullHeight: calc(100vh - 90px);
}

body {
    font-family: 'Avant Garde Pro';
    font-size: 16px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
    font-weight: 300;
}

/********************
*
* Typography
*
*********************/

h1 {
    @apply text-[50px] leading-[1.1] font-semibold;
}

strong {
    @apply font-semibold;
}

.prose h2 {
    @apply text-[36px] leading-[1.1] font-semibold my-[50px];
}

.prose p,
.prose a,
.prose li {
    @apply text-[16px] leading-[1.5] my-[40px] font-light;
}

.prose ol,
.prose ul {
    @apply my-[40px]
}

.prose.prose-sm p,
.prose.prose-sm a,
.prose.prose-sm li {
    @apply my-[20px];
}

.prose.prose-sm ol,
.prose.prose-sm ul {
    @apply my-[20px]
}

.prose ul {
    @apply pl-[30px];
}

.prose ul li {
    @apply my-[20px] list-none relative;
}

.prose ul li:before {
    @apply content-[''] block w-[20px] h-[3px] bg-black absolute -left-[30px] top-[8px];
}

.prose img {
    @apply my-0;
}

.prose .image {
    @apply my-[60px];
}

.prose .image .caption{
    @apply text-[#686868] text-[13px] leading-[1.3846] mt-[10px] font-light;
}

.prose blockquote {
    @apply mt-[100px] border-0 p-0 pb-[70px] text-[36px] leading-[1.1111] font-semibold not-italic max-w-[500px] relative;
}

.prose blockquote span{
    @apply relative;
}

.prose blockquote::before,
.prose blockquote::after {
    @apply content-[''] block absolute w-[68px] h-[73px];
    background-image: url(../img/quote.png);
}

.prose blockquote::before {
    @apply top-0 left-0 rotate-180;
}
.prose blockquote::after {
    @apply bottom-0 right-0;
}

/* .prose *:last-child{
    margin-bottom: 0 !important;
}

.prose *:first-child{
    margin-top: 0 !important;
} */

.prose .file a {
    @apply my-0;
}

.prose .file a svg {
    @apply mr-2
}

.intro-block p{
    @apply text-[20px] leading-[1.4];
}

/********************
*
* Buttons
*
*********************/

.btn,
.prose a.btn {
    @apply inline-block bg-[#fcda00] font-semibold rounded-full no-underline py-[20px] pb-[18px] px-[30px] duration-200;
}

.btn.btn-sm,
.prose a.btn.btn-sm {
    @apply py-[5px] pb-[2px] px-[10px] text-[11px];
}

.btn.btn-md,
.prose a.btn.btn-md {
    @apply py-[15px] pb-[13px] px-[20px];
}

@media(hover: hover){
    .btn:hover:not(.btn-tag),
    .prose a.btn:hover:not(.btn-tag) {
        @apply bg-black text-[#fcda00];
    }
}

/********************
*
* Form elements
*
*********************/

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    @apply block w-full resize-none font-light;
}

input[type="checkbox"],
input[type="radio"] {
    @apply text-black;
}

select {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus,
textarea:focus,
select:focus{
    box-shadow: none!important;
    border-color: #000;
}

input[type="text"]:focus::placeholder,
input[type="email"]:focus::placeholder,
input[type="password"]:focus::placeholder,
textarea:focus::placeholder{
    @apply text-transparent;
}

/********************
*
* Cards
*
*********************/

.card-contact {
    @apply bg-white p-[20px] h-full;
}

.card-contact .avatar{
    @apply mr-[15px];
    flex: 65px 0 0;
}

/********************
*
* Layout
*
*********************/

#main {
    @apply bg-[#fcda00] px-[20px] relative ;
}

#main::before, 
#main::after {
    @apply bg-[#fcda00] block fixed h-screen top-0 bottom-0 z-[1000];
    content: '';
}

#main::before{
    @apply left-0;
}

#main::after {
    @apply right-0;
}

article {
    @apply bg-white pb-[20px];
    min-height: var(--fullHeight);
}

.featured-posts {
    height: var(--fullHeight);
}

#main-head {
    @apply bg-[#fcda00] sticky top-0 h-[70px] z-[1000];
}

#main-head .container {
    @apply h-full;
}

#main-foot {
    @apply fixed p-[20px] w-full h-screen bg-[#fcda00] left-0 right-0 z-[999] ease-in-out duration-500;
    top: calc(100% - 20px);
}

#main-foot.open {
    top : 0;
}

#main-foot ul {
    @apply duration-500 delay-300 opacity-0;
}

#main-foot.open ul {
    @apply opacity-100;
}

.container {
    @apply mx-auto max-w-[1320px] px-[20px];
}

.container-md {
    @apply max-w-[1120px];
}

@md {
    .container{
        @apply px-[40px];
    }
}

/********************
*
* Menu Toggle
*
*********************/
.menu-toggle {
    @apply block w-[35px] h-[25px] relative;
}

.menu-toggle::before,
.menu-toggle::after {
    content: '';
    transition: transform 100ms ease-in-out, top 100ms 100ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
}

.menu-toggle::before,
.menu-toggle::after,
.menu-toggle span {
    @apply absolute block w-full h-[3px] bg-black origin-center;
}

.menu-toggle::before {
    @apply top-0;
}

.menu-toggle span {
    @apply top-[10px] opacity-100;
    transition: opacity 200ms linear;
}

.menu-toggle::after {
    @apply top-[20px];
}

.menu-toggle.close span {
    @apply opacity-0;
}

.menu-toggle.close::before,
.menu-toggle.close::after {
    transition: top 100ms cubic-bezier(0.250, 0.250, 0.750, 0.750), transform 100ms 100ms ease-in-out;
    @apply top-[10px];
}

.menu-toggle.close::before {
    @apply -rotate-45;
}

.menu-toggle.close::after {
    @apply rotate-45;
}

/********************
*
* SECTION Featured posts
*
*********************/
section.featured-posts a{
    @apply inline-block;
}

section.featured-posts a.post-title{
    font-size: 75px;
    font-weight: 600;
    line-height: 1;
}

section.featured-posts .post-author{
    font-size: 12px;
    line-height: 1.16667;
}

section.featured-posts .read-more {
    @apply text-[#fcda00] inline-block relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.25px;
}

section.featured-posts .read-more::after{
    @apply block h-[2px] w-full bg-[#fcda00] mt-[5px] duration-100;
    content: '';
}

section.featured-posts .read-more:hover::after{
    @apply w-0;
}

/********************
*
* SECTION Home Article List
*
*********************/

.home-article-list {
    @apply py-[100px];
}

.home-article-list .article-item {
    @apply overflow-hidden h-0 pt-[140.54%] relative block bg-white;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.2);
}

.home-article-list .article-item img {
    @apply absolute inset-0 block w-full h-full max-w-none;
}

.home-article-list .article-item img.main-img {
    @apply opacity-100;
}

.home-article-list .article-item img.hover-img {
    @apply opacity-0;
}


@media (hover: hover) {
    .home-article-list .article-item img {
        @apply duration-700;
    }
    
    .home-article-list .article-item:hover img.main-img {
        @apply opacity-0;
    }
    
    .home-article-list .article-item:hover img.hover-img {
        @apply opacity-100;
    }
}

/********************
*
* RATINGS Component
*
*********************/

@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
/* Ratings widget */
.rate {
    display: inline-block;
    border: 0;
}
/* Hide radio */
.rate > input {
    display: none;
}
/* Order correctly by floating highest to the right */
.rate > label {
    float: right;
}
/* The star of the show */
.rate > label:before {
    display: inline-block;
    font-size: 2rem;
    margin: 0;
    cursor: pointer;
    font-family: FontAwesome;
    content: "\f005 "; /* full star */
    padding-right: 0.2rem;
}

/* Half star trick */
.rate .half:before {
    content: "\f089 "; /* half star no outline */
    position: absolute;
    padding-right: 0;
}


.rate.rate-sm > label:before {
    font-size: 1rem;
}


/* Click + hover color */
.rate input:checked ~ label, /* color current and previous stars on checked */
.rate:not(.disabled) label:hover, 
.rate:not(.disabled) label:hover ~ label { color: #fcda00;  } /* color previous stars on hover */

/* Hover highlights */
.rate:not(.disabled) input:checked + label:hover, 
.rate:not(.disabled) input:checked ~ label:hover, /* highlight current and previous stars */
.rate:not(.disabled) input:checked ~ label:hover ~ label, /* highlight previous selected stars for new rating */
.rate:not(.disabled) label:hover ~ input:checked ~ label /* highlight previous selected stars */ { color: #fce758;  } 